import SocialMedia from '@components/Hero/SocialMedia';
import { useInView } from 'react-intersection-observer';
import AnimatedText from 'react-animated-text-content';
import Typewriter from 'typewriter-effect';
import { useEffect, useState } from 'react';
import { classNames, strapiMedia } from '@utils/helpers';

const Hero = ({ poster, video, description }) => {
    const [animate, setAnimate] = useState(false);
    const [displayScroll, setDisplayScroll] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            animate && setDisplayScroll(true);
        }, 1500);
    }, [animate]);

    const { ref, inView } = useInView({
        triggerOnce: true,
        delay: 500
    });

    return (
        <div ref={ref} className="h-96 md:h-screen w-full hero-image z-[-1] bg-no-repeat bg-cover">
            <video
                poster={strapiMedia(poster)}
                className="absolute z-[-2] h-96 md:h-screen w-full object-cover"
                id="background-video"
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={strapiMedia(video)} type={video.data.attributes.mime} />
            </video>
            <div className="backdrop-filter backdrop-brightness-50 flex h-full w-full py-8 px-4 -mt-24 items-center lg:py-16 lg:px-12">
                <div className="grid grid-cols-12 mx-auto w-full max-w-screen-xl text-center">
                    <div className="col-span-11">
                        <h1 className="mb-8 text-md md:text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl text-white">
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                        .typeString("Let's Chill")
                                        .pauseFor(500)
                                        .deleteAll()
                                        .typeString('Keep cool ...')
                                        .pauseFor(500)
                                        .deleteAll()
                                        .typeString('Welcome to Frostan Limited')
                                        .callFunction(() => {
                                            setAnimate(true);
                                        })
                                        .start();
                                }}
                            />
                        </h1>
                        {animate && (
                            <AnimatedText
                                animationType={'blocks'}
                                type={'words'}
                                className="md:mb-8 text-sm md:text-lg text-center mx-auto font-semibold max-w-screen-md lg:text-2xl sm:px-16 xl:px-44 text-gray-100"
                                tag={'h2'}
                                duration={0.5}
                                animation={{
                                    ease: 'ease-in-out'
                                }}
                            >
                                {description}
                            </AnimatedText>
                        )}
                    </div>
                    <div className="col-span-1">
                        <SocialMedia
                            iconSize={'h-6 w-6 md:h-8 md:w-8'}
                            spacing={'space-y-3 md:space-y-5'}
                        />
                    </div>
                </div>
                <h3
                    className={classNames(
                        'text-gray-100 text-xs md:text-lg absolute bottom-2 left-1/2 transform -translate-x-1/2',
                        displayScroll && inView ? 'visible' : 'invisible'
                    )}
                >
                    Scroll to see more ...
                    <p className="text-center animate-bounce">&darr;</p>
                </h3>
            </div>
        </div>
    );
};

export default Hero;
