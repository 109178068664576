import { classNames } from '@utils/helpers';

const Arrow = ({ children, disabled, onClick }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(
                'mx-1 flex flex-col justify-center right-1 select-none',
                disabled ? 'opacity-0' : 'opacity-100 cursor-pointer'
            )}
        >
            {children}
        </button>
    );
};

export { Arrow };
