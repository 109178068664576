import { RiUser6Line } from 'react-icons/ri';
import { useState } from 'react';
import { StrapiConnector } from '@config/Axios';
import { toast } from 'react-hot-toast/headless';
import { Toast } from '@components/Toasts';

const Newsletter = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        StrapiConnector.post('/subscribers', { data: { name: name, email: email } })
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Thank you for subscribing to Frostan. ', {
                        position: 'top-right',
                        className: 'shadow-xl'
                    });
                    setName('');
                    setEmail('');
                }
            })
            .catch(() => {
                toast.error('Failed to subscribe to Frostan. Please try again.', {
                    position: 'top-right',
                    className: 'shadow-xl'
                });
            });
    };

    return (
        <>
            <section className="bg-white">
                <div className="py-3 md:py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-lg text-center flex flex-col items-center">
                        <div>
                            <h2 className="mb-4 text-base md:text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                Subscribe to our newsletter
                            </h2>
                            <p className="mx-auto mb-8 max-w-2xl font-light text-gray-500 md:mb-12 text-sm sm:text-xl">
                                Stay updated with our news
                            </p>
                        </div>
                        <form className="w-15 md:w-full">
                            <div className="items-center mx-auto mb-3 max-w-screen-sm space-x-2 sm:space-x-0 flex sm:space-y-0">
                                <div className="relative w-1/2">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <RiUser6Line className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                                    </div>
                                    <input
                                        className="block p-3 pl-10 w-full text-xs md:text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500"
                                        placeholder="Enter your name"
                                        type="text"
                                        id="name"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="relative w-1/2">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                        </svg>
                                    </div>
                                    <input
                                        className="block p-3 pl-10 w-full text-xs md:text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none focus:ring-primary-500 focus:border-primary-500"
                                        placeholder="Enter your email"
                                        type="email"
                                        id="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button
                                        onClick={handleSubmit}
                                        type="submit"
                                        className="py-3 px-5 w-full text-xs md:text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary-900 border-primary-800 sm:rounded-none sm:rounded-r-lg hover:bg-primary-600 focus:ring-4 focus:ring-primary-100"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <Toast />
        </>
    );
};

export default Newsletter;
