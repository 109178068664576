import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import React from 'react';
import { Arrow } from '@components/Blog/Arrow';
import { TfiArrowCircleLeft, TfiArrowCircleRight } from 'react-icons/tfi';
import { classNames } from '@utils/helpers';

const HorizontalScroll = ({ children, ...props }) => {
    return (
        <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            itemClassName={'scroll-item-packed'}
            scrollContainerClassName={props.className}
            options={{
                ratio: 0.9,
                rootMargin: '5px',
                threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
            }}
        >
            {children}
        </ScrollMenu>
    );
};

const LeftArrow = ({ classes }) => {
    const { getPrevElement, isFirstItemVisible, scrollToItem, visibleElements, initComplete } =
        React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    // NOTE: for scroll 1 item
    const clickHandler = () => scrollToItem(getPrevElement(), 'smooth', 'start');
    return (
        <Arrow disabled={disabled} onClick={clickHandler}>
            <TfiArrowCircleLeft
                className={classNames('text-primary-900 h-6 w-6 mx-2 sm:mx-6', classes)}
            />
        </Arrow>
    );
};

const RightArrow = ({ classes }) => {
    const { getNextElement, isLastItemVisible, scrollToItem, visibleElements } =
        React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(!visibleElements.length && isLastItemVisible);
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    // NOTE: for scroll 1 item
    const clickHandler = () => scrollToItem(getNextElement(), 'smooth', 'end');
    return (
        <Arrow disabled={disabled} onClick={clickHandler}>
            <TfiArrowCircleRight
                className={classNames('text-primary-900 h-6 w-6 mx-2 sm:mx-6', classes)}
            />
        </Arrow>
    );
};

export default HorizontalScroll;
export { LeftArrow, RightArrow };
