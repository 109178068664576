import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import { classNames } from '@utils/helpers';
import { FaPlay } from 'react-icons/fa';
import { DisclosureCard } from '@components/Cards/DisclosureCard';
import { BrandContent } from '@components/Brands/BrandContent';

const Brands = ({ list }) => {
    return (
        <div className="max-w-6xl mx-auto w-full h-full py-8">
            <div className="hidden grow w-full sm:flex flex-grow justify-around items-center">
                <Tab.Group vertical>
                    <Tab.List className="space-y-4 sm:space-y-8 lg:space-y-12 py-2 sm:py-4 lg:py-8 w-1/3 sm:w-1/5 p-1 h-full pl-2 sm:pl-4 flex flex-col justify-center">
                        {list.map((brand) => (
                            <Tab as={Fragment} key={brand.id}>
                                {({ selected }) => (
                                    <h5
                                        className={classNames(
                                            'relative text-sm md:text-lg py-1 md:py-2.5 leading-3 md:leading-5',
                                            'cursor-pointer focus-visible:outline-0',
                                            selected
                                                ? 'text-primary-500'
                                                : 'text-primary-900 hover:bg-white/[0.12] hover:text-black'
                                        )}
                                    >
                                        {brand.attributes.Name}
                                        <FaPlay
                                            className={classNames(
                                                'absolute top-1/2 -right-5 sm:-right-6 transform -translate-y-1/2',
                                                selected ? 'visible' : 'invisible'
                                            )}
                                        />
                                    </h5>
                                )}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="w-2/3 sm:w-4/5 h-full md:py-4 border-l-2 border-primary-500">
                        {list.map((brand, idx) => (
                            <Tab.Panel
                                key={brand.id}
                                className={classNames(
                                    'h-full flex flex-col will-change-[opacity,transform] h-full md:py-4 sm:px-8 lg:px-16 xl:px-28'
                                )}
                            >
                                <BrandContent brand={brand} idx={idx} />
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <div className="flex gap-2 flex-col sm:hidden">
                {list.map((brand, i) => (
                    <DisclosureCard key={brand.id} open={i === 0} title={brand.attributes.Name}>
                        <BrandContent brand={brand} idx={i} logoHeight="h-24" className="p-4" />
                    </DisclosureCard>
                ))}
            </div>
        </div>
    );
};

Brands.propTypes = {
    list: PropTypes.array.isRequired
};

export default Brands;
