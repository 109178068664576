import { classNames, strapiDeepMedia } from '@utils/helpers';
import { BrandLogo } from '@components/Brands/BrandLogo';

const BrandContent = ({ brand, logoHeight, idx, ...props }) => {
    return (
        <div {...props}>
            <div
                className={classNames(
                    'grid w-full',
                    brand.attributes.Images['data'].length > 1 ? 'grid-cols-2 gap-3' : 'grid-col-1',
                    logoHeight
                )}
            >
                {brand.attributes.Images['data'].map((media, i) => {
                    if (i > 1) {
                        return;
                    }

                    return (
                        <BrandLogo
                            key={media.attributes.hash}
                            src={strapiDeepMedia(media)}
                            alt={media.attributes.alternativeText}
                            priority={idx === 0}
                            classes="w-full"
                            blurDataURL={media.attributes.placeholder}
                        />
                    );
                })}
            </div>
            <div className="px-2 lg:px-16 xl:px-28 py-3 py-1 sm:py-8">
                <p className="text-xs md:text-lg line-clamp-4 text-justify">
                    {brand.attributes.Description}
                </p>
            </div>
            <div className="w-full text-center py-6 text-xs sm:text-md uppercase text-white">
                {brand.attributes.Action && brand.attributes.available && (
                    <a
                        href={brand.attributes.Action}
                        className="text-white bg-primary-900 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
                    >
                        Visit online Shop
                    </a>
                )}
                {!brand.attributes.available && (
                    <span className="font-bold underline text-primary-900 text-md sm:text-xl">
                        Coming Soon
                    </span>
                )}
            </div>
        </div>
    );
};

BrandContent.defaultProps = {
    logoHeight: 'h-36 sm:h-44 lg:h-60'
};

export { BrandContent };
