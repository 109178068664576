import Link from 'next/link';
import { classNames, strapiMedia } from '@utils/helpers';
import { useState } from 'react';

const Services = ({ bgColor, services, children }) => {
    return (
        <div className={`flex justify-center ${bgColor} w-full py-5 md:py-16`}>
            <div className="max-w-screen-lg text-white">
                {children}
                <div className="grid grid-cols-2 md:grid-cols-3 md:gap-x-10 gap-3 md:gap-y-8 w-full mb-8 px-4 md:px-6">
                    {services.map((service) => (
                        <ServiceCard service={service} key={service.attributes.slug} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const ServiceCard = ({ service, ...props }) => {
    const [hover, set] = useState(false);
    return (
        <div {...props} onMouseEnter={() => set(true)} onMouseLeave={() => set(false)}>
            <Link href={`/services#${service.attributes.slug}`}>
                <a
                    className={classNames(
                        'group flex flex-col justify-center relative hidden md:block',
                        'text-center py-4 h-32 md:h-44 hover:text-primary-800',
                        'bg-no-repeat bg-[length:50px] md:bg-[length:75px] bg-[center_bottom_1rem] hover:bg-none hover:bg-primary-100',
                        'transition-all duration-500',
                        'rounded-lg border-2 border-gray-500'
                    )}
                    style={{
                        backgroundImage: !hover
                            ? `url("${strapiMedia(service.attributes.icon)}")`
                            : 'none'
                    }}
                >
                    <h5 className="text-lg cursor-pointer">{service.attributes.name}</h5>
                    <div className="px-4 py-3">
                        <p className="text-left text-sm invisible line-clamp-2 group-hover:visible group-hover:text-gray-800">
                            {service.attributes.description}
                        </p>
                    </div>
                    <div
                        className={classNames(
                            'invisible group-hover:visible group-hover:text-gray-800 group-hover:text-primary-800',
                            'absolute -bottom-2 w-full'
                        )}
                    >
                        <div
                            className={classNames(
                                'h-10 w-13 md:w-15 font-bold rounded-lg text-xs md:text-xs'
                            )}
                        >
                            Read More
                            <div className="absolute w-6 h-1 bottom-[-2] rounded-lg left-0 right-0 mx-auto bg-white" />
                        </div>
                    </div>
                </a>
            </Link>
            {/*Small screen Responsive*/}
            <Link href={`/services#${service.attributes.slug}`} passHref>
                <div
                    className={`group block md:hidden bg-no-repeat bg-[length:50px] md:bg[length:100px] bg-bottom transition-all duration-500 rounded-lg border-2 border-gray-500 text-center  md:pr-4 py-4 h-32 md:h-44 hover:text-primary-800`}
                    style={{
                        backgroundImage: `url("${strapiMedia(service.attributes.icon)}")`
                    }}
                >
                    <h5 className="text-xs">{service.attributes.name}</h5>
                </div>
            </Link>
        </div>
    );
};

Services.defaultProps = {
    bgColor: '',
    children: (
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-0 w-full mb-12">
            <div className="border-r-2 border-primary-500">
                <h4 className="px-1 text-2xl md:text-4xl font-bold uppercase">
                    Services <br /> we are providing
                </h4>
            </div>
            <div className="flex h-full items-center px-4 md:px-8 py-4">
                <p className="text-sm">
                    With our state of the art cold chain technology we provide a vast range of
                    services for both farmers and businesses.
                </p>
            </div>
        </div>
    )
};

export default Services;
