import Image from 'next/image';
import PropTypes from 'prop-types';

const BrandLogo = ({ src, alt, fit, layout, classes, priority, ...props }) => {
    return (
        <div className={`col-span-1 relative flex justify-center ${classes}`} {...props}>
            <Image
                src={src}
                alt={alt}
                objectFit={fit}
                layout={layout}
                priority={priority}
                placeholder="blur"
                {...props}
            />
        </div>
    );
};

BrandLogo.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

BrandLogo.defaultProps = {
    alt: '',
    fit: 'contain',
    layout: 'fill',
    classes: 'h-36 sm:h-44 lg:h-60'
};

export { BrandLogo };
