import { useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import PropTypes from 'prop-types';
import { classNames, strapiDeepMedia } from '@utils/helpers';
import Contact from '@components/Forms/Contact';

const SpringCarousel = ({ slides, duration, delay, transition, children, indicators }) => {
    const [index, setIndex] = useState(0);
    const transitions = useTransition(index, {
        key: index,
        from: { opacity: 0.3 },
        enter: { opacity: 1 },
        leave: { opacity: 0.4 },
        transition: transition,
        delay: delay,
        config: { duration: duration },
        onRest: (_a, _b, item) => {
            if (index === item) {
                setIndex((state) => (state + 1) % slides.length);
            }
        },
        exitBeforeEnter: true
    });

    const scrollTo = (index) => {
        setIndex(index);
    };
    return (
        <section className="flex flex-col relative fill center mx-8 my-16">
            {transitions((style, i) => (
                <animated.div
                    className="will-change-[opacity] bg-cover bg-center rounded-2xl w-full h-[28rem]"
                    style={{
                        ...style,
                        backgroundImage: `url(${strapiDeepMedia(slides[i])})`
                    }}
                />
            ))}
            {children}
            {indicators && (
                <div className="flex space-x-3 justify-center my-4">
                    {slides.map((e, i) => (
                        <button
                            key={`Slide ${i + 1}`}
                            type="button"
                            className={classNames(
                                'w-3 h-3 rounded-full hover:bg-primary-500',
                                index === i ? 'bg-primary-600' : 'bg-primary-200'
                            )}
                            aria-current={(index === i).toString()}
                            aria-label={`Slide ${i + 1}`}
                            onClick={() => scrollTo(i)}
                        />
                    ))}
                </div>
            )}
        </section>
    );
};

const CarouselCard = () => {
    const [open, setOpen] = useState(false);
    return (
        <div className="rounded-[1rem] text-gray-800 bg-gray-50 w-[15rem] md:w-[28rem] h-[12rem] md:h-[15rem] absolute right-2 bottom-14 md:bottom-20 p-6 md:p-8">
            <h3 className="text-base md:text-2xl mb-3 md:mb-6">
                Are you a potential supplier, customer, investor or an NGO Partner?
            </h3>
            <div className="text-center mb-4">
                <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="text-white bg-primary-900 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
                >
                    Contact Us
                </button>
            </div>
            <Contact isOpen={open} setIsOpen={setOpen} />
        </div>
    );
};

SpringCarousel.propTypes = {
    slides: PropTypes.array.isRequired,
    duration: PropTypes.number,
    delay: PropTypes.number,
    indicators: PropTypes.bool
};

SpringCarousel.defaultProps = {
    duration: 3500,
    delay: 1000,
    transition: { ease: 'easeIn', duration: 1 },
    indicators: true
};

export default SpringCarousel;

export { CarouselCard };
