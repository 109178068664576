import Hero from '@components/Hero';
import Newsletter from '@components/Newsletter';
import Quotation from '@components/CTA/Quotation';
import Services from '@components/CTA/Services';
import SpringCarousel, { CarouselCard } from '@components/Carousel/Spring';
import Image from 'next/image';
import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { classNames, strapiMedia, strapiUrl } from '@utils/helpers';
import Brands from '@components/Brands';
import Link from 'next/link';
import { StrapiConnector } from '@config/Axios';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { Seo } from '@components/Meta/Seo';
import HorizontalScroll from '@components/HorizontalScroll';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

export default function Home({ home, brands, stakeholders, services }) {
    const { ref: beliefRef, inView: beliefInView } = useInView({
        delay: 500,
        threshold: 0.1,
        triggerOnce: true
    });
    const { ref: produceRef, inView: produceInView } = useInView({
        triggerOnce: true
    });

    // noinspection JSUnresolvedFunction
    const farm = home.attributes.farmTable.find((e) => e['__component'] === 'section.farm');
    // noinspection JSUnresolvedFunction
    const service = home.attributes.farmTable.find((e) => e['__component'] === 'section.services');
    // noinspection JSUnresolvedFunction
    const brand = home.attributes.farmTable.find((e) => e['__component'] === 'section.brands');

    return (
        <>
            <Seo meta={home} />
            <Hero
                description={home.attributes.hero['description']}
                poster={home.attributes.hero['poster']}
                video={home.attributes.hero['background']}
            />
            <section
                className={classNames(
                    'transition-opacity duration-1000 flex w-full pt-16 pb-4 md:py-16 px-1 md:px-12 justify-center',
                    beliefInView ? 'opacity-100 animate-fade-in' : 'opacity-0'
                )}
            >
                <div
                    ref={beliefRef}
                    className="bg-splat bg-no-repeat bg-[length:130px] md:bg-[length:350px] md:bg-[left_2rem_bottom] px-6 py-2 md:py-8 grid grid-cols-5 gap-2 md:gap-8 max-w-4xl"
                >
                    <div className="col-span-2 relative h-56 md:h-[28rem] md:mb-8">
                        <Image
                            src={strapiMedia(home.attributes.belief['poster'])}
                            layout={'fill'}
                            alt="Frostan's Belief"
                            objectFit={'contain'}
                            placeholder="blur"
                            blurDataURL={
                                home.attributes.belief['poster'].data.attributes.placeholder
                            }
                        />
                    </div>
                    <div className="col-span-3">
                        <div className="flex justify-center flex-col h-full">
                            <blockquote className="text-xs md:text-xl font-bold italic text-gray-900 pl-3 md:pl-24 mb-2 md:my-6">
                                &quot;{home.attributes.belief['quote']}&quot;
                            </blockquote>
                            <p className="text-xs md:text-xl text-right mb-4 font-satisfy">
                                A frostan belief
                            </p>
                            <p className="text-xs md:text-lg pl-3 md:pl-24 w-full text-justify">
                                {home.attributes.belief['description']}
                            </p>
                            <div className="text-right mt-4 md:mt-8">
                                <Link passHref href="/story">
                                    <a className="text-white bg-primary-900 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                                        Read More
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className={classNames(
                    'transition-opacity opacity-0 duration-500 flex w-full py-2 px-1 md:px-12 justify-center',
                    produceInView ? 'animate-fade-in-bottom opacity-100' : ''
                )}
            >
                <div
                    ref={produceRef}
                    className="px-4 py-5 md:py-0 md:px-6 grid md:grid-cols-6 gap-3 md:gap-8 max-w-4xl"
                >
                    <div className="md:col-span-3">
                        <div className="flex justify-center flex-col h-full px-1 md:px-4">
                            <h3 className="text-xl md:text-4xl font-bold mb-6">
                                {home.attributes.produce['title']}
                            </h3>
                            <p className="text-sm md:text-lg w-[250px] md:w-full">
                                {home.attributes.produce['description']}
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-3">
                        <div className="relative h-[176px] md:h-72">
                            <Image
                                src={strapiMedia(home.attributes.produce['poster'])}
                                alt="Veggie Supermarket"
                                layout={'fill'}
                                objectFit={'contain'}
                                placeholder="blur"
                                blurDataURL={
                                    home.attributes.produce['poster'].data.attributes.placeholder
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="w-full grid md:grid-cols-2 pt-5">
                <div className="md:col-span-1 h-[220px] md:h-screen relative">
                    <Image
                        src={strapiMedia(farm.cover)}
                        alt="From Farm"
                        layout={'fill'}
                        objectFit={'cover'}
                        placeholder="blur"
                        blurDataURL={farm.cover.data.attributes.placeholder}
                    />
                </div>
                <div className="md:col-span-1 h-full md:h-screen bg-primary-100 py-7 px-4 md:p-7">
                    <p className="text-md italic md:text-xl">{farm.title}</p>
                    <div className="h-full w-[284px] md:w-full flex items-center py-2 pr-30 md:pr-32">
                        <h4 className="text-[20px] md:text-5xl font-bold leading-[25px]">
                            {farm.content}
                        </h4>
                    </div>
                </div>
            </section>
            <section className="w-full pt-5 px-1 md:p-8 bg-primary-900">
                <p className="text-md md:text-xl italic font-normal px-4 md:px-0 text-white">
                    {service.title}
                </p>
                <Services services={services}>
                    <div className="grid md:grid-cols-2 gap-0 w-full mb-10">
                        <div className={'grid grid-cols-2 md:block my-1 sm:my-8 lg:my-16'}>
                            <h4 className="text-[20px] md:text-4xl pl-4 md:pl-8 font-bold capitalize ">
                                {service.heading}
                            </h4>
                        </div>
                        <div className="flex h-full items-center px-4 md:px-8 py-1 md:py-4">
                            <p className="text-sm md:text-lg">{service.description}</p>
                        </div>
                    </div>
                </Services>
            </section>
            <section className="w-full p-8 sm:px-12 lg:p-12 lg:px-16 lg:min-h-active">
                <p className="text-sm md:text-xl italic font-semibold">{brand.title}</p>
                <div className="grid grid-cols-4 my-4 sm:my-8 lg:my-16">
                    <div className="text-xl md:text-4xl font-bold capitalize col-span-3">
                        <h4 className="max-w-md">{brand.heading}</h4>
                    </div>
                </div>
                <Brands list={brands} />
            </section>
            <Quotation
                bg={home.attributes.quotation['cover']}
                text={home.attributes.quotation['text']}
                action={home.attributes.quotation['action']}
            />
            <section className="w-full flex flex-col mx-auto max-w-6xl px-6 md:px-2 my-5 sm:px-6 lg:px-8 mt-12">
                <div className="grid grid-cols-4 my-4 sm:my-8 lg:my-16">
                    <div className="text-xl md:text-4xl font-bold capitalize px-2 md:px-10 col-span-3">
                        <h4 className="max-w-md">Our affiliated stakeholders</h4>
                    </div>
                </div>
                <HorizontalScroll>
                    {stakeholders.map((partner) => (
                        <PartnerCard
                            partner={partner}
                            stakeholders={stakeholders}
                            key={partner.id}
                        />
                    ))}
                </HorizontalScroll>
            </section>
            <SpringCarousel slides={home.attributes.Slides['data']}>
                <CarouselCard />
            </SpringCarousel>
            <section
                className="w-full bg-cover bg-center h-48 sm:h-[40rem]"
                style={{
                    backgroundImage: `url("${strapiMedia(
                        home.attributes.sustainability['cover']
                    )}")`
                }}
            >
                <div className="grid grid-cols-6 w-full h-full px-1 md:px-8 md:py-16">
                    <div className="col-span-3"></div>
                    <div className="col-span-3 flex flex-col w-full items-center my-auto">
                        <h5 className="text-white w-full text-sm md:text-4xl mb-3 md:mb-8">
                            {home.attributes.sustainability['title']}
                        </h5>
                        <p className="text-xs md:text-lg w-full text-white md:pr-24 mb-3 md:mb-8">
                            {home.attributes.sustainability['description']}
                        </p>
                        <div className="w-full">
                            <button
                                type="button"
                                className="text-white bg-primary-900 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
                            >
                                <a href={'/impact'}>{home.attributes.sustainability['action']}</a>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="w-full flex flex-col space-y-8 mx-auto max-w-6xl px-2 my-5 sm:px-6 lg:px-8 mt-12">*/}
            {/*    <h4 className="text-base md:text-4xl text-primary-900 font-bold">Blog</h4>*/}
            {/*    <Blog articles={articles} />*/}
            {/*</section>*/}
            <Newsletter />
        </>
    );
}

const PartnerCard = ({ partner, stakeholders, ...props }) => {
    const visibility = useContext(VisibilityContext);

    const visible = visibility.isItemVisible(partner.id);

    setTimeout(() => {
        if (visibility.isLastItemVisible) {
            visibility.scrollToItem(visibility.getItemElementById(stakeholders?.[0].id));
        }
        visibility.scrollNext();
    }, 3000);

    return (
        <div
            role="button"
            tabIndex={partner.id}
            className={classNames(
                'aspect-square transition ease-in-out duration-200 flex mx-2 w-44 relative',
                visible ? 'grayscale-0' : 'grayscale'
            )}
            itemID={partner.id}
            {...props}
        >
            <Image
                src={strapiMedia(partner.attributes.logo)}
                alt={partner.attributes.logo['data'].attributes['alternativeText']}
                layout={'fill'}
                objectFit={'contain'}
                placeholder="blur"
                blurDataURL={partner.attributes.logo['data'].attributes.placeholder}
            />
        </div>
    );
};

export async function getStaticProps() {
    const queryClient = new QueryClient();
    const [
        {
            data: { data: home }
        },
        {
            data: { data: brands }
        },
        {
            data: { data: stakeholders }
        },
        {
            data: { data: services }
        }
    ] = await Promise.all([
        StrapiConnector.get(
            strapiUrl('/home-page', {
                populate: ['deep', 5]
            })
        ),
        StrapiConnector.get(
            strapiUrl('/brands', {
                populate: '*',
                sort: {
                    priority: 'desc'
                }
            })
        ),
        StrapiConnector.get(
            strapiUrl('/stakeholders', {
                populate: '*'
            })
        ),
        StrapiConnector.get(
            strapiUrl('/services', {
                populate: ['name', 'description', 'icon', 'slug']
            })
        )
    ]);

    return {
        props: {
            home: home,
            brands: brands,
            stakeholders: stakeholders,
            services: services,
            dehydratedState: dehydrate(queryClient)
        },
        revalidate: 60
    };
}
