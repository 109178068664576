import { Disclosure, Transition } from '@headlessui/react';
import { BsChevronUp } from 'react-icons/bs';
import { classNames } from '@utils/helpers';
const DisclosureCard = ({ title, key, classes, open, children }) => {
    return (
        <Disclosure key={key} defaultOpen={open}>
            {({ open }) => (
                <div className={classes.general}>
                    <Disclosure.Button
                        className={classNames(
                            'flex w-full justify-between px-4 py-2 text-left focus:outline-none focus-visible:ring focus-visible:ring-opacity-75',
                            classes.title,
                            open ? 'font-semibold' : 'font-medium'
                        )}
                    >
                        <span className={'text-sm md:text-lg'}>{title}</span>
                        <BsChevronUp
                            className={classNames(
                                'h-5 w-5 text-white',
                                !open ? 'rotate-180 transform' : ''
                            )}
                        />
                    </Disclosure.Button>
                    <Transition
                        show={open}
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-300 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel static className="px-4 py-2 text-sm md:text-lg">
                            {children}
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    );
};

DisclosureCard.defaultProps = {
    classes: {
        general: '',
        title: 'rounded-lg bg-primary-900 text-md text-white hover:bg-primary-800 focus-visible:ring-primary-500',
        details: 'text-gray-500'
    },
    open: false
};

export { DisclosureCard };
